<template>

  <drawer-form
    ref="drawerForm"
    :title="model.id > 0?'编辑':'添加'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">


    <a-form-model-item label='功能名称' prop="name">
      <a-input v-model="model.name"/>
    </a-form-model-item>

    <a-form-model-item label='索引' prop="index">
      <a-input v-model="model.index"/>
    </a-form-model-item>

    <a-form-model-item label='长度' prop="length">
      <a-input v-model="model.length"/>
    </a-form-model-item>

    <a-form-model-item label='备注' prop="remark">
      <a-textarea v-model="model.remark"/>
    </a-form-model-item>

  </drawer-form>

</template>

<script>

  export default {
    data () {
      return {
        isShow: false,
        model: {
          id: null,
          productKey: null,
          name: null,
          index: null,
          length: null,
          remark: ""
        },
        rules:[

        ],
        loading: false,

      }
    },
    methods: {
      show (model = {}) {
        this.model = {...model}
        this.$refs.drawerForm.show()
      },
      hide () {
        this.$refs.drawerForm.hide()
      },

      handleSubmit () {
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },

      add () {
        this.loading = true
        this.$post('web/product/model', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      },
      edit () {
        this.loading = true
        this.$put('web/product/model', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      }

    },

  }
</script>

<style>

</style>
